import { READ_ACTION, REPORT_RESOURCE } from "@/constants/resources";

export default [
  {
    title: "Relatórios",
    icon: "none",
    children: [
      {
        title: "Assinaturas inadimplentes",
        route: "overdue-signature-report",
        icon: "TrendingDownIcon",
        resource: REPORT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Contratos de PNs inativos",
        route: "inactive-pn-contracts-report",
        icon: "AlertTriangleIcon",
        resource: REPORT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Clientes de PNs inativos",
        route: "inactive-pn-customers-report",
        icon: "UserXIcon",
        resource: REPORT_RESOURCE,
        action: READ_ACTION,
      },
    ],
  },
];
