import {
  READ_ACTION,
  TRANSFER_ACTION,
  CONTRACT_RESOURCE,
  CONSULTANT_RESOURCE,
  LEAD_PN
} from "@/constants/resources";

var navMenu = [
  {
    title: "Gestão de PNs",
    icon: "none",
    children: [
      {
        title: "Leads PNs",
        route: "potential-partners-list",
        icon: "UserCheckIcon",
        resource: LEAD_PN,
        action: READ_ACTION,
      },
      {
        title: "Minha equipe",
        route: "consultants-list",
        icon: "UsersIcon",
        resource: CONSULTANT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Transferir PN",
        route: "consultant-transfer",
        icon: "RepeatIcon",
        resource: CONSULTANT_RESOURCE,
        action: TRANSFER_ACTION,
      },
      {
        title: "Feedz",
        href: "https://app.feedz.com.br/inicio",
        icon: "UserCheckIcon",
        resource: CONTRACT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Sólides",
        href: "https://solides.com.br/",
        icon: "SmileIcon",
        resource: CONTRACT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Links úteis",
        href: "https://drive.google.com/file/d/1B09b1thbe_ot-2F1k2ZztAagH_wz4Isr/view",
        icon: "ListIcon",
        resource: CONTRACT_RESOURCE,
        action: READ_ACTION,
      },
      {
        title: "Links úteis franqueados",
        href: "https://drive.google.com/file/d/1nTqXUFJThergG8r8phSVPfe9rPtvN0f5/view?usp=sharing",
        icon: "BookIcon",
        resource: CONSULTANT_RESOURCE,
        action: TRANSFER_ACTION,
      }
    ].filter(Boolean),
  },
];

export default navMenu;
