import { 
  READ_ACTION, 
  CONTRACT_RESOURCE, 
  BUSINESS_INTELLIGENCE_RESOURCE, 
  SUBSCRIPTIONS_STATICS,
  KPI
} from "@/constants/resources";

export default [
  // {
  //   header: "Indicadores",
  //   resource: CONTRACT_RESOURCE,
  //   action: READ_ACTION,
  // },
  {
    title: "Indicadores",
    icon: "none",
    children: [
      {
        title: "Faturômetro",
        route: "kpi-list",
        icon: "ActivityIcon",
        resource: KPI,
        action: READ_ACTION,
      },
      {
        title: "Ranking compromissos",
        route: "kpi-ranking-appoiments",
        icon: "CalendarIcon",
        resource: KPI,
        action: READ_ACTION,
      },
      {
        title: "Ranking faturamento",
        route: "kpi-ranking-invoicing",
        icon: "BarChart2Icon",
        resource: KPI,
        action: READ_ACTION,
      },
      {
        title: "Ranking PN Especialista",
        route: "kpi-ranking-specialist",
        icon: "TrendingUpIcon",
        resource: KPI,
        action: READ_ACTION,
      },
      {
        title: "Ranking Pilares",
        route: "kpi-ranking-pilares",
        icon: "ArrowUpRightIcon",
        resource: KPI,
        action: READ_ACTION,
      },
      {
        title: "Estatísticas de Assinatura",
        route: "kpi-subscriptions-statistics",
        icon: "PieChartIcon",
        resource: BUSINESS_INTELLIGENCE_RESOURCE,
        action: SUBSCRIPTIONS_STATICS,
      },
    ],
  },
];
