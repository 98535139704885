import { ANNOUNCEMENT_RESOURCE, DASHBOARD_BANNER, READ_ACTION, PROFILE, MANAGE_ACTION } from "@/constants/resources";

export default [
  {
    title: "Configurações",
    icon: "none",
    children: [
      {
        title: "Gerenciamento de banners",
        route: "dashboard-banners",
        icon: "ImageIcon",
        resource: DASHBOARD_BANNER,
        action: READ_ACTION,
      },
      {
        title: "Acessos e Permissões",
        route: "dashboard-banners",
        icon: "ImageIcon",
        resource: PROFILE,
        action: MANAGE_ACTION,
      },
    ],
  }
];
