import {
  READ_ACTION,
  FRANCHISE,
  TRANSFER_ACTION,
} from "@/constants/resources";

export default [
  // {
  //   header: "Gestão de clientes",
  //   resource: LEAD_RESOURCE,
  //   action: READ_ACTION,
  // },
  {
    title: "Franquias",
    icon: "none",
    resource: FRANCHISE,
    action: READ_ACTION,
    children: [
      // 2nd level
      {
        title: "Franquias físicas",
        route: "physical-franchise-list",
        icon: "HomeIcon",
        resource: FRANCHISE,
        action: READ_ACTION,
      },
      {
        title: "Franquias digitais",
        route: "franchisees-list",
        icon: "MousePointerIcon",
        resource: FRANCHISE,
        action: TRANSFER_ACTION,
      },
    ],
  },
];
